import { PropsWithChildren, createContext, useEffect, useState } from 'react'

export const AppContext = createContext({
  darkMode: false,
  toggleDarkMode: () => {},
})

export const AppProvider = ({ children }: PropsWithChildren) => {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const stored = window.localStorage.getItem('darkMode')
    setDarkMode(
      stored
        ? JSON.parse(stored)
        : window.matchMedia('(prefers-color-scheme: dark)').matches,
    )
  }, [])

  useEffect(() => {
    window.localStorage.setItem('darkMode', JSON.stringify(darkMode))
  }, [darkMode])

  return (
    <AppContext.Provider
      value={{ darkMode, toggleDarkMode: () => setDarkMode(!darkMode) }}
    >
      {children}
    </AppContext.Provider>
  )
}
